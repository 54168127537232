<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <path
      style="fill:#9B735A;"
      d="M414.897,35.31H273.655c0,9.75-7.905,17.655-17.655,17.655s-17.655-7.905-17.655-17.655H97.103
	c-14.626,0-26.483,11.857-26.483,26.483v423.724c0,14.626,11.857,26.483,26.483,26.483h317.793
	c14.626,0,26.483-11.857,26.483-26.483V61.793C441.379,47.167,429.523,35.31,414.897,35.31z"
    />
    <path
      style="fill:#8C5F50;"
      d="M97.103,52.966c-4.875,0-8.828,3.953-8.828,8.828v423.724c0,4.875,3.953,8.828,8.828,8.828h317.793
	c4.875,0,8.828-3.953,8.828-8.828V61.793c0-4.875-3.953-8.828-8.828-8.828h-52.966c-4.875,0-8.828-3.953-8.828-8.828V35.31h-79.448
	c0,9.75-7.905,17.655-17.655,17.655s-17.655-7.905-17.655-17.655h-79.448v8.828c0,4.875-3.953,8.828-8.828,8.828H97.103z"
    />
    <path
      style="fill:#F1F4FB;"
      d="M397.241,476.69H114.759c-4.875,0-8.828-3.953-8.828-8.828V79.448c0-4.875,3.953-8.828,8.828-8.828
	h282.483c4.875,0,8.828,3.953,8.828,8.828v388.414C406.069,472.737,402.116,476.69,397.241,476.69z"
    />
    <path
      style="fill:#FFF082;"
      d="M317.793,17.655h-31.38C280.297,7.145,269.036,0,256,0s-24.297,7.145-30.413,17.655h-31.38
	c-9.75,0-17.655,7.904-17.655,17.655v35.31c0,4.875,3.953,8.828,8.828,8.828h141.241c4.875,0,8.828-3.953,8.828-8.828V35.31
	C335.448,25.559,327.543,17.655,317.793,17.655z M256,48.552c-7.313,0-13.241-5.929-13.241-13.241S248.687,22.069,256,22.069
	c7.313,0,13.241,5.929,13.241,13.241S263.313,48.552,256,48.552z"
    />
    <circle style="fill:#FF507D;" cx="176.552" cy="176.552" r="26.483" />
    <circle style="fill:#FF8C66;" cx="176.552" cy="273.655" r="26.483" />
    <circle style="fill:#FFC850;" cx="176.552" cy="370.759" r="26.483" />
    <path
      style="fill:#7F8499;"
      d="M361.931,300.138H238.345c-4.879,0-8.828-3.953-8.828-8.828s3.948-8.828,8.828-8.828h123.586
	c4.879,0,8.828,3.953,8.828,8.828S366.81,300.138,361.931,300.138z"
    />
    <path
      style="fill:#5B5D6E;"
      d="M326.621,264.828h-88.276c-4.879,0-8.828-3.953-8.828-8.828s3.948-8.828,8.828-8.828h88.276
	c4.879,0,8.828,3.953,8.828,8.828S331.5,264.828,326.621,264.828z"
    />
    <path
      style="fill:#7F8499;"
      d="M361.931,397.241H238.345c-4.879,0-8.828-3.953-8.828-8.828s3.948-8.828,8.828-8.828h123.586
	c4.879,0,8.828,3.953,8.828,8.828S366.81,397.241,361.931,397.241z"
    />
    <path
      style="fill:#5B5D6E;"
      d="M326.621,361.931h-88.276c-4.879,0-8.828-3.953-8.828-8.828s3.948-8.828,8.828-8.828h88.276
	c4.879,0,8.828,3.953,8.828,8.828S331.5,361.931,326.621,361.931z"
    />
    <path
      style="fill:#7F8499;"
      d="M361.931,203.034H238.345c-4.879,0-8.828-3.953-8.828-8.828s3.948-8.828,8.828-8.828h123.586
	c4.879,0,8.828,3.953,8.828,8.828S366.81,203.034,361.931,203.034z"
    />
    <path
      style="fill:#5B5D6E;"
      d="M326.621,167.724h-88.276c-4.879,0-8.828-3.953-8.828-8.828c0-4.875,3.948-8.828,8.828-8.828h88.276
	c4.879,0,8.828,3.953,8.828,8.828C335.448,163.772,331.5,167.724,326.621,167.724z"
    />
    <path
      style="fill:#FFDC64;"
      d="M326.621,79.448H185.379c-4.875,0-8.828-3.953-8.828-8.828l0,0c0-4.875,3.953-8.828,8.828-8.828
	h141.241c4.875,0,8.828,3.953,8.828,8.828l0,0C335.448,75.496,331.496,79.448,326.621,79.448z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
